import PropTypes from 'prop-types'
import { hasPermission, useAuth0 } from '../../auth0'

const Can = ({ yes = () => null, no = () => null, requiredPermission }) => {
  const { permissions } = useAuth0()
  const requiredPermissions = [requiredPermission]

  return hasPermission(permissions, requiredPermissions)
    ? yes()
    : no()
}

Can.propTypes = {
  no: PropTypes.func,
  requiredPermission: PropTypes.string,
  yes: PropTypes.func,
}

export default Can
