import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { omit } from 'lodash'
import Icon, {
  AppstoreOutlined,
  BankOutlined,
  BarcodeOutlined,
  CalendarOutlined,
  CarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DashboardOutlined,
  DownOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  SearchOutlined,
  ShopOutlined,
  UploadOutlined,
} from '@ant-design/icons'

const iconTypes = {
  appstore: AppstoreOutlined,
  bank: BankOutlined,
  barcode: BarcodeOutlined,
  calendar: CalendarOutlined,
  car: CarOutlined,
  checkCircle: CheckCircleOutlined,
  close: CloseOutlined,
  closeCircle: CloseCircleOutlined,
  dashboard: DashboardOutlined,
  delete: DeleteOutlined,
  downArrow: DownOutlined,
  download: DownloadOutlined,
  edit: EditOutlined,
  filter: FilterOutlined,
  info: InfoCircleOutlined,
  logOut: LogoutOutlined,
  search: SearchOutlined,
  shop: ShopOutlined,
  upload: UploadOutlined,
}

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

const CustomIcon = React.forwardRef((props, ref) => {
  const cleanedProps = props.disabled ? omit(props, 'onClick') : props

  return <Icon component={iconTypes[props.type]} ref={ref} {...cleanedProps} />
})

const StyledIcon = styled(({ disabled = false, ...props }) => <CustomIcon disabled={disabled} {...props} />)`
  color: ${props => props.color || 'default'};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'} !important;

  opacity: ${({ disabled }) => disabled ? .4 : 1};

  font-size: 14px;
  margin: 0 5px;

  &:hover {
    color: #0095FF;
  };
`

CustomIcon.displayName = 'Icon'

CustomIcon.propTypes = {
  color: PropTypes.string,
  cursor: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
}

export default StyledIcon
