import React from 'react'
import { useAsync } from 'react-async'
import { Helmet } from 'react-helmet'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import { Layout } from 'antd'
import {
  BrandsPage,
  DepositDetailsPage,
  DepositsPage,
  Error404Page,
  HomePage,
  PackagesPage,
  RetailersPage,
  CategoriesPage,
  SuppliersPage,
} from './pages'
import { AuthenticationLayer, ErrorMessage, Profile, Sidebar } from './components'
import { getConfigs } from './api'
import { Auth0Provider } from './auth0'
import favIcons from './assets/images/favicon'

const onRedirectCallback = appState => {
  // Temporary Firefox workaround
  window.location.hash = window.location.hash // eslint-disable-line no-self-assign

  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

const App = () => {
  const { data, error, isLoading } = useAsync({
    promiseFn: getConfigs,
  })

  const { Header, Content, Footer } = Layout

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data} />
  if (isLoading) return null

  return (
    <>
      <Helmet
        defaultTitle="Loop DMS"
        link={[
          { href: favIcons.appleTouchIcon, rel: 'apple-touch-icon' },
          { href: favIcons.defaultFavicon, rel: 'icon' },
          { href: favIcons.androidIcon32, rel: 'icon', sizes: '32x32' },
          { href: favIcons.androidIcon16, rel: 'icon', sizes: '16x16' },
          { color: '#2D9CDB', href: favIcons.safariIcon, rel: 'mask-icon' },
        ]}
        meta={[
          { content: 'Loop DMS', name: 'author' },
          { content: 'index, follow', name: '”robots”' },
        ]}
      />

      <Auth0Provider
        authorizationParams={{
          audience: data?.audience,
          redirect_uri: window.location.origin,
        }}
        cacheLocation="localstorage"
        clientId={data?.clientId}
        domain={data?.domain}
        onRedirectCallback={onRedirectCallback}
      >
        <BrowserRouter>

          <AuthenticationLayer>
            <Layout style={{ minHeight: '100vh' }}>
              <Sidebar />

              <Layout>
                <Header style={{ background: '#fff', padding: '0 16px' }}>
                  <Profile />
                </Header>

                <Content style={{ margin: '16px' }}>
                  <div
                    style={{ background: '#fff', minHeight: 360, padding: 24 }}
                  >
                    <Routes>
                      <Route element= {<HomePage />} exact path="/" />
                      <Route element= {<SuppliersPage />} exact path="/suppliers" />
                      <Route element= {<BrandsPage />} exact path="/brands" />
                      <Route element= {<DepositDetailsPage />} exact path="/deposits/:id" />
                      <Route element= {<DepositsPage />} path="/deposits" />
                      <Route element= {<PackagesPage />} path="/packages" />
                      <Route element= {<CategoriesPage />} path="/categories" />
                      <Route element= {<RetailersPage />} path="/retailers" />
                      <Route element= {<Error404Page />} path="*" />
                    </Routes>
                  </div>
                </Content>

                <Footer style={{ textAlign: 'center' }}>
                Loop DMS © {new Date().getFullYear()}
                </Footer>
              </Layout>
            </Layout>
          </AuthenticationLayer>
        </BrowserRouter>
      </Auth0Provider>
    </>
  )
}

export default App
