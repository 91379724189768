import androidIcon16 from './favicon-16x16.png'
import androidIcon32 from './favicon-32x32.png'
import appleTouchIcon from './apple-touch-icon.png'
import defaultFavicon from './favicon.ico'
import safariIcon from './safari-pinned-tab.svg'

const favIcons = {
  androidIcon16,
  androidIcon32,
  appleTouchIcon,
  defaultFavicon,
  safariIcon,
}

export default favIcons

